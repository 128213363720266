import { graphql } from 'gatsby'
import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next'
import React from 'react'

import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const PrivacyPolicy = () => {
  const { language } = useI18next()
  const { t } = useTranslation('pages', { keyPrefix: 'terms.privacyPolicy' })

  return (
    <>
      <SEO title={t('title')} />
      <div className="flex flex-col h-screen justify-between">
        <Navigation />
        <ContentMain>
          <HeaderTitle title={t('title')} />
          <ContentSubPage>
            <p>
              <Trans i18nKey="intro.text.0" t={t}>
                Valid from: January 01, 2020
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.1" t={t}>
                Flagis s.r.o. (&quot;us&quot;, &quot;we&quot;, or
                &quot;our&quot;) operates the{' '}
                <Link language={language} to="/">
                  https://www.flagis.com
                </Link>{' '}
                website and the Flagis mobile application (hereinafter referred
                to as the &quot;Service&quot;).
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.2" t={t}>
                This page informs you of our policies regarding the collection,
                use and disclosure of personal data when you use our Service and
                the choices you have associated with that data.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.3" t={t}>
                We use your data to provide and improve the Service. By using
                the Service, you agree to the collection and use of information
                in accordance with this policy. Unless otherwise defined in this
                Privacy Policy, the terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="definitions.title" t={t}>
                Definitions
              </Trans>
            </h3>
            <ul>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="definitions.text.0" t={t}>
                      Service
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="definitions.text.1" t={t}>
                    Service means the <a href="/">https://www.flagis.com</a>{' '}
                    website and the Flagis mobile application operated by Flagis
                    s.r.o.
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="definitions.text.2" t={t}>
                      Personal Data
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="definitions.text.3" t={t}>
                    Personal Data means data about a living individual who can
                    be identified from those data (or from those and other
                    information either in our possession or likely to come into
                    our possession).
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="definitions.text.4" t={t}>
                      Usage Data
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="definitions.text.5" t={t}>
                    Usage Data is data collected automatically either generated
                    by the use of the Service or from the Service infrastructure
                    itself (for example, the duration of a page visit).
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="definitions.text.6" t={t}>
                      Cookies
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="definitions.text.7" t={t}>
                    Cookies are small files stored on your device (computer or
                    mobile device).
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="definitions.text.8" t={t}>
                      Data Controller
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="definitions.text.9" t={t}>
                    Data Controller means the natural or legal person who
                    (either alone or jointly or in common with other persons)
                    determines the purposes for which and the manner in which
                    any personal information are, or are to be, processed.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="definitions.text.10" t={t}>
                    For the purpose of this Privacy Policy, we are a Data
                    Controller of your Personal Data.
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="definitions.text.11" t={t}>
                      Data Processors (or Service Providers)
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="definitions.text.12" t={t}>
                    Data Processor (or Service Provider) means any natural or
                    legal person who processes the data on behalf of the Data
                    Controller.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="definitions.text.13" t={t}>
                    We may use the services of various Service Providers in
                    order to process your data more effectively.
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="definitions.text.14" t={t}>
                      Data Subject (or User)
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="definitions.text.15" t={t}>
                    Data Subject is any living individual who is using our
                    Service and is the subject of Personal Data.
                  </Trans>
                </p>
              </li>
            </ul>
            <h3>
              <Trans i18nKey="collectionAndUse.title" t={t}>
                Information Collection and Use
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="collectionAndUse.text.0" t={t}>
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="typesOfData.title" t={t}>
                Types of Data Collected
              </Trans>
            </h3>
            <h4>
              <Trans i18nKey="typesOfData.personalData.title" t={t}>
                Personal Data
              </Trans>
            </h4>
            <p>
              <Trans i18nKey="typesOfData.personalData.text.0" t={t}>
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you (&quot;Personal Data&quot;). Personally
                identifiable information may include, but is not limited to:
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="typesOfData.personalData.text.1" t={t}>
                  Email address
                </Trans>
              </li>
              <li>
                <Trans i18nKey="typesOfData.personalData.text.2" t={t}>
                  First name and last name
                </Trans>
              </li>
              <li>
                <Trans i18nKey="typesOfData.personalData.text.3" t={t}>
                  Phone number
                </Trans>
              </li>
              <li>
                <Trans i18nKey="typesOfData.personalData.text.4" t={t}>
                  Address, State, Province, ZIP/Postal code, City
                </Trans>
              </li>
              <li>
                <Trans i18nKey="typesOfData.personalData.text.5" t={t}>
                  Cookies and Usage Data
                </Trans>
              </li>
            </ul>
            <p>
              <Trans i18nKey="typesOfData.personalData.text.6" t={t}>
                We may use your Personal Data to contact you with newsletters,
                marketing or promotional materials and other information that
                may be of interest to you. You may opt out of receiving any, or
                all, of these communications from us by following the
                unsubscribe link or instructions provided in any email we send
                or by contacting us.
              </Trans>
            </p>
            <h4>
              <Trans i18nKey="typesOfData.usageData.title" t={t}>
                Usage Data
              </Trans>
            </h4>
            <p>
              <Trans i18nKey="typesOfData.usageData.text.0" t={t}>
                We may also collect information that your browser sends whenever
                you visit our Service or when you access the Service by or
                through a mobile device (&quot;Usage Data&quot;).
              </Trans>
            </p>
            <p>
              <Trans i18nKey="typesOfData.usageData.text.1" t={t}>
                This Usage Data may include information such as your
                computer&apos;s Internet Protocol address (e.g. IP address),
                browser type, browser version, the pages of our Service that you
                visit, the time and date of your visit, the time spent on those
                pages, unique device identifiers and other diagnostic data.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="typesOfData.usageData.text.2" t={t}>
                When you access the Service with a mobile device, this Usage
                Data may include information such as the type of mobile device
                you use, your mobile device unique ID, the IP address of your
                mobile device, your mobile operating system, the type of mobile
                Internet browser you use, unique device identifiers and other
                diagnostic data.
              </Trans>
            </p>
            <h4>
              <Trans i18nKey="typesOfData.locationData.title" t={t}>
                Location Data
              </Trans>
            </h4>
            <p>
              <Trans i18nKey="typesOfData.locationData.text.0" t={t}>
                We may use and store information about your location if you give
                us permission to do so (&quot;Location Data&quot;). We use this
                data to provide features of our Service, to improve and
                customise our Service.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="typesOfData.locationData.text.1" t={t}>
                You can enable or disable location services when you use our
                Service at any time by way of your device settings.
              </Trans>
            </p>
            <h4>
              <Trans i18nKey="typesOfData.cookiesData.title" t={t}>
                Tracking &amp; Cookies Data
              </Trans>
            </h4>
            <p>
              <Trans i18nKey="typesOfData.cookiesData.text.0" t={t}>
                We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="typesOfData.cookiesData.text.1" t={t}>
                Cookies are files with a small amount of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Other tracking
                technologies are also used such as beacons, tags and scripts to
                collect and track information and to improve and analyse our
                Service.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="typesOfData.cookiesData.text.2" t={t}>
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="typesOfData.cookiesData.text.3" t={t}>
                Examples of Cookies we use:
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="typesOfData.cookiesData.text.4" t={t}>
                  <strong>Session Cookies.</strong> We use Session Cookies to
                  operate our Service.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="typesOfData.cookiesData.text.5" t={t}>
                  <strong>Preference Cookies.</strong> We use Preference Cookies
                  to remember your preferences and various settings.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="typesOfData.cookiesData.text.6" t={t}>
                  <strong>Security Cookies.</strong> We use Security Cookies for
                  security purposes.
                </Trans>
              </li>
            </ul>
            <h3>
              <Trans i18nKey="useOfData.title" t={t}>
                Use of Data
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="useOfData.text.0" t={t}>
                Flagis s.r.o. uses the collected data for various purposes:
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="useOfData.text.1" t={t}>
                  To provide and maintain our Service
                </Trans>
              </li>
              <li>
                <Trans i18nKey="useOfData.text.2" t={t}>
                  To notify you about changes to our Service
                </Trans>
              </li>
              <li>
                <Trans i18nKey="useOfData.text.3" t={t}>
                  To allow you to participate in interactive features of our
                  Service when you choose to do so
                </Trans>
              </li>
              <li>
                <Trans i18nKey="useOfData.text.4" t={t}>
                  To provide customer support
                </Trans>
              </li>
              <li>
                <Trans i18nKey="useOfData.text.5" t={t}>
                  To gather analysis or valuable information so that we can
                  improve our Service
                </Trans>
              </li>
              <li>
                <Trans i18nKey="useOfData.text.6" t={t}>
                  To monitor the usage of our Service
                </Trans>
              </li>
              <li>
                <Trans i18nKey="useOfData.text.7" t={t}>
                  To detect, prevent and address technical issues
                </Trans>
              </li>
              <li>
                <Trans i18nKey="useOfData.text.8" t={t}>
                  To provide you with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless you have opted not to
                  receive such information
                </Trans>
              </li>
            </ul>
            <h3>
              <Trans i18nKey="gdpr.title" t={t}>
                Legal Basis for Processing Personal Data under the General Data
                Protection Regulation (GDPR)
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="gdpr.text.0" t={t}>
                If you are from the European Economic Area (EEA), Flagis s.r.o.
                legal basis for collecting and using the personal information
                described in this Privacy Policy depends on the Personal Data we
                collect and the specific context in which we collect it.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="gdpr.text.1" t={t}>
                Flagis s.r.o. may process your Personal Data because:
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="gdpr.text.2" t={t}>
                  We need to perform a contract with you
                </Trans>
              </li>
              <li>
                <Trans i18nKey="gdpr.text.3" t={t}>
                  You have given us permission to do so
                </Trans>
              </li>
              <li>
                <Trans i18nKey="gdpr.text.4" t={t}>
                  The processing is in our legitimate interests and it is not
                  overridden by your rights
                </Trans>
              </li>
              <li>
                <Trans i18nKey="gdpr.text.5" t={t}>
                  For payment processing purposes
                </Trans>
              </li>
              <li>
                <Trans i18nKey="gdpr.text.6" t={t}>
                  To comply with the law
                </Trans>
              </li>
            </ul>
            <h3>
              <Trans i18nKey="retentionOfData.title" t={t}>
                Retention of Data
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="retentionOfData.text.0" t={t}>
                Flagis s.r.o. will retain your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes and enforce our legal agreements and policies.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="retentionOfData.text.1" t={t}>
                Flagis s.r.o. will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of our Service, or we
                are legally obligated to retain this data for longer periods.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="transferOfData.title" t={t}>
                Transfer of Data
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="transferOfData.text.0" t={t}>
                Your information, including Personal Data, may be transferred to
                - and maintained on - computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="transferOfData.text.1" t={t}>
                If you are located outside Czech Republic and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to Czech Republic and process it there.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="transferOfData.text.2" t={t}>
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="transferOfData.text.3" t={t}>
                Flagis s.r.o. will take all the steps reasonably necessary to
                ensure that your data is treated securely and in accordance with
                this Privacy Policy and no transfer of your Personal Data will
                take place to an organisation or a country unless there are
                adequate controls in place including the security of your data
                and other personal information.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="disclosureOfData.title" t={t}>
                Disclosure of Data
              </Trans>
            </h3>
            <h4>
              <Trans i18nKey="disclosureOfData.businessTransaction.title" t={t}>
                Business Transaction
              </Trans>
            </h4>
            <p>
              <Trans
                i18nKey="disclosureOfData.businessTransaction.text.0"
                t={t}
              >
                If Flagis s.r.o. is involved in a merger, acquisition or asset
                sale, your Personal Data may be transferred. We will provide
                notice before your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </Trans>
            </p>
            <h4>
              <Trans i18nKey="disclosureOfData.disclosure.title" t={t}>
                Disclosure for Law Enforcement
              </Trans>
            </h4>
            <p>
              <Trans i18nKey="disclosureOfData.disclosure.text.0" t={t}>
                Under certain circumstances, Flagis s.r.o. may be required to
                disclose your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
              </Trans>
            </p>
            <h4>
              <Trans i18nKey="disclosureOfData.legalRequirements.title" t={t}>
                Legal Requirements
              </Trans>
            </h4>
            <p>
              <Trans i18nKey="disclosureOfData.legalRequirements.text.0" t={t}>
                Flagis s.r.o. may disclose your Personal Data in the good faith
                belief that such action is necessary to:
              </Trans>
            </p>
            <ul>
              <li>
                <Trans
                  i18nKey="disclosureOfData.legalRequirements.text.1"
                  t={t}
                >
                  To comply with a legal obligation
                </Trans>
              </li>
              <li>
                <Trans
                  i18nKey="disclosureOfData.legalRequirements.text.2"
                  t={t}
                >
                  To protect and defend the rights or property of Flagis s.r.o.
                </Trans>
              </li>
              <li>
                <Trans
                  i18nKey="disclosureOfData.legalRequirements.text.3"
                  t={t}
                >
                  To prevent or investigate possible wrongdoing in connection
                  with the Service
                </Trans>
              </li>
              <li>
                <Trans
                  i18nKey="disclosureOfData.legalRequirements.text.4"
                  t={t}
                >
                  To protect the personal safety of users of the Service or the
                  public
                </Trans>
              </li>
              <li>
                <Trans
                  i18nKey="disclosureOfData.legalRequirements.text.5"
                  t={t}
                >
                  To protect against legal liability
                </Trans>
              </li>
            </ul>
            <h3>
              <Trans i18nKey="securityOfData.title" t={t}>
                Security of Data
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="securityOfData.text.0" t={t}>
                The security of your data is important to us but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </Trans>
            </p>
            <h4>
              <Trans i18nKey="securityOfData.text.1" t={t}>
                Our Policy on &quot;Do Not Track&quot; Signals under the
                California Online Protection Act (CalOPPA)
              </Trans>
            </h4>
            <p>
              <Trans i18nKey="securityOfData.text.2" t={t}>
                We do not support Do Not Track (&quot;DNT&quot;). Do Not Track
                is a preference you can set in your web browser to inform
                websites that you do not want to be tracked.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="securityOfData.text.3" t={t}>
                You can enable or disable Do Not Track by visiting the
                Preferences or Settings page of your web browser.
              </Trans>
            </p>
            <h4>
              <Trans i18nKey="securityOfData.text.4" t={t}>
                Your Data Protection Rights under the General Data Protection
                Regulation (GDPR)
              </Trans>
            </h4>
            <p>
              <Trans i18nKey="securityOfData.text.5" t={t}>
                If you are a resident of the European Economic Area (EEA), you
                have certain data protection rights. Flagis s.r.o. aims to take
                reasonable steps to allow you to correct, amend, delete or limit
                the use of your Personal Data.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="securityOfData.text.6" t={t}>
                If you wish to be informed about what Personal Data we hold
                about you and if you want it to be removed from our systems,
                please contact us.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="securityOfData.text.7" t={t}>
                In certain circumstances, you have the following data protection
                rights:
              </Trans>
            </p>
            <ul>
              <li>
                <p>
                  <Trans i18nKey="securityOfData.text.8" t={t}>
                    <strong>
                      The right to access, update or delete the information we
                      have on you.
                    </strong>{' '}
                    Whenever made possible, you can access, update or request
                    deletion of your Personal Data directly within your account
                    settings section. If you are unable to perform these actions
                    yourself, please contact us to assist you.
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="securityOfData.text.9" t={t}>
                    <strong>The right of rectification.</strong> You have the
                    right to have your information rectified if that information
                    is inaccurate or incomplete.
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="securityOfData.text.10" t={t}>
                    <strong>The right to object.</strong> You have the right to
                    object to our processing of your Personal Data.
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="securityOfData.text.11" t={t}>
                    <strong>The right of restriction.</strong> You have the
                    right to request that we restrict the processing of your
                    personal information.
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="securityOfData.text.12" t={t}>
                    <strong>The right to data portability.</strong> You have the
                    right to be provided with a copy of the information we have
                    on you in a structured, machine-readable and commonly used
                    format.
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="securityOfData.text.13" t={t}>
                    <strong>The right to withdraw consent.</strong> You also
                    have the right to withdraw your consent at any time where
                    Flagis s.r.o. relied on your consent to process your
                    personal information.
                  </Trans>
                </p>
              </li>
            </ul>
            <p>
              <Trans i18nKey="securityOfData.text.14" t={t}>
                Please note that we may ask you to verify your identity before
                responding to such requests.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="securityOfData.text.15" t={t}>
                You have the right to complain to a Data Protection Authority
                about our collection and use of your Personal Data. For more
                information, please contact your local data protection authority
                in the European Economic Area (EEA).
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="serviceProviders.title" t={t}>
                Service Providers
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="serviceProviders.text.0" t={t}>
                We may employ third party companies and individuals to
                facilitate our Service (&quot;Service Providers&quot;), provide
                the Service on our behalf, perform Service-related services or
                assist us in analysing how our Service is used.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="serviceProviders.text.1" t={t}>
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
              </Trans>
            </p>
            <h4>
              <Trans i18nKey="serviceProviders.analytics.title" t={t}>
                Analytics
              </Trans>
            </h4>
            <p>
              <Trans i18nKey="serviceProviders.analytics.text.0" t={t}>
                We may use third-party Service Providers to monitor and analyse
                the use of our Service.
              </Trans>
            </p>
            <ul>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.analytics.text.1" t={t}>
                      Google Analytics
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.analytics.text.2" t={t}>
                    Google Analytics is a web analytics service offered by
                    Google that tracks and reports website traffic. Google uses
                    the data collected to track and monitor the use of our
                    Service. This data is shared with other Google services.
                    Google may use the collected data to contextualise and
                    personalise the ads of its own advertising network.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.analytics.text.3" t={t}>
                    For more information on the privacy practices of Google,
                    please visit the Google Privacy &amp; Terms web page:{' '}
                    <a href="https://policies.google.com/privacy?hl=en">
                      https://policies.google.com/privacy?hl=en
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.analytics.text.4" t={t}>
                      Firebase
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.analytics.text.5" t={t}>
                    Firebase is an analytics service provided by Google Inc.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.analytics.text.6" t={t}>
                    You may opt-out of certain Firebase features through your
                    mobile device settings, such as your device advertising
                    settings or by following the instructions provided by Google
                    in their Privacy Policy:{' '}
                    <a href="https://policies.google.com/privacy?hl=en">
                      https://policies.google.com/privacy?hl=en
                    </a>
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.analytics.text.7" t={t}>
                    We also encourage you to review the Google&apos;s policy for
                    safeguarding your data:{' '}
                    <a href="https://support.google.com/analytics/answer/6004245">
                      https://support.google.com/analytics/answer/6004245
                    </a>
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.analytics.text.8" t={t}>
                    For more information on what type of information Firebase
                    collects, please visit the Google Privacy &amp; Terms web
                    page:{' '}
                    <a href="https://policies.google.com/privacy?hl=en">
                      https://policies.google.com/privacy?hl=en
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.analytics.text.9" t={t}>
                      Piwik / Matomo
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.analytics.text.10" t={t}>
                    Piwik or Matomo is a web analytics service. You can visit
                    their Privacy Policy page here:{' '}
                    <a href="https://matomo.org/privacy-policy">
                      https://matomo.org/privacy-policy
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.analytics.text.11" t={t}>
                      Clicky
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.analytics.text.12" t={t}>
                    Clicky is a web analytics service. Read the Privacy Policy
                    for Clicky here:{' '}
                    <a href="https://clicky.com/terms">
                      https://clicky.com/terms
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.analytics.text.13" t={t}>
                      Statcounter
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.analytics.text.14" t={t}>
                    Statcounter is a web traffic analysis tool. You can read the
                    Privacy Policy for Statcounter here:{' '}
                    <a href="https://statcounter.com/about/legal/">
                      https://statcounter.com/about/legal/
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.analytics.text.15" t={t}>
                      Unity Analytics
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.analytics.text.16" t={t}>
                    Unity Analytics is provided by Unity Technologies.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.analytics.text.17" t={t}>
                    For more information on what type of information Unity
                    Analytics collects, please visit their Privacy Policy page:{' '}
                    <a href="https://unity3d.com/legal/privacy-policy">
                      https://unity3d.com/legal/privacy-policy
                    </a>
                  </Trans>
                </p>
              </li>
            </ul>
            <h4>
              <Trans i18nKey="serviceProviders.payments.title" t={t}>
                Payments
              </Trans>
            </h4>
            <p>
              <Trans i18nKey="serviceProviders.payments.text.0" t={t}>
                We may provide paid products and/or services within the Service.
                In that case, we use third-party services for payment processing
                (e.g. payment processors).
              </Trans>
            </p>
            <p>
              <Trans i18nKey="serviceProviders.payments.text.1" t={t}>
                We will not store or collect your payment card details. That
                information is provided directly to our third-party payment
                processors whose use of your personal information is governed by
                their Privacy Policy. These payment processors adhere to the
                standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa,
                MasterCard, American Express and Discover. PCI-DSS requirements
                help ensure the secure handling of payment information.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="serviceProviders.payments.text.2" t={t}>
                The payment processors we work with are:
              </Trans>
            </p>
            <ul>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.3" t={t}>
                      Apple Store In-App Payments
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.4" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://www.apple.com/legal/privacy/en-ww/">
                      https://www.apple.com/legal/privacy/en-ww/
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.5" t={t}>
                      Google Play In-App Payments
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.6" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://www.google.com/policies/privacy/">
                      https://www.google.com/policies/privacy/
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.7" t={t}>
                      Stripe
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.8" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://stripe.com/us/privacy">
                      https://stripe.com/us/privacy
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.9" t={t}>
                      WePay
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.10" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://go.wepay.com/privacy-policy">
                      https://go.wepay.com/privacy-policy
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.11" t={t}>
                      WorldPay
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.12" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://www.worldpay.com/en-gb/privacy-policy">
                      https://www.worldpay.com/en-gb/privacy-policy
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.13" t={t}>
                      PayPal / Braintree
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.14" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">
                      https://www.paypal.com/webapps/mpp/ua/privacy-full
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.15" t={t}>
                      FastSpring
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.16" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href=" http://fastspring.com/privacy/">
                      http://fastspring.com/privacy/
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.17" t={t}>
                      Authorize.net
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.18" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://www.authorize.net/company/privacy/">
                      https://www.authorize.net/company/privacy/
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.19" t={t}>
                      2Checkout
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.20" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://www.2checkout.com/policies/privacy-policy">
                      https://www.2checkout.com/policies/privacy-policy
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.21" t={t}>
                      Sage Pay
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.22" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://www.sagepay.co.uk/policies">
                      https://www.sagepay.co.uk/policies
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.23" t={t}>
                      Square
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.24" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://squareup.com/legal/privacy-no-account">
                      https://squareup.com/legal/privacy-no-account
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.25" t={t}>
                      Go Cardless
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.26" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://gocardless.com/en-eu/legal/privacy/">
                      https://gocardless.com/en-eu/legal/privacy/
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.27" t={t}>
                      Elavon
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.28" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://www.elavon.com/privacy-pledge.html">
                      https://www.elavon.com/privacy-pledge.html
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.29" t={t}>
                      Verifone
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.30" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://www.verifone.com/en/us/legal">
                      https://www.verifone.com/en/us/legal
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.31" t={t}>
                      Moneris
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.32" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://www.moneris.com/en/Privacy-Policy">
                      https://www.moneris.com/en/Privacy-Policy
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.33" t={t}>
                      WeChat
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.34" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://www.wechat.com/en/privacy_policy.html">
                      https://www.wechat.com/en/privacy_policy.html
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <Trans i18nKey="serviceProviders.payments.text.35" t={t}>
                      Alipay
                    </Trans>
                  </strong>
                </p>
                <p>
                  <Trans i18nKey="serviceProviders.payments.text.36" t={t}>
                    Their Privacy Policy can be viewed at{' '}
                    <a href="https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html">
                      https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html
                    </a>
                  </Trans>
                </p>
              </li>
            </ul>
            <h3>
              <Trans i18nKey="linksToOtherSites.title" t={t}>
                Links to Other Sites
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="linksToOtherSites.text.0" t={t}>
                Our Service may contain links to other sites that are not
                operated by us. If you click a third party link, you will be
                directed to that third party&apos;s site. We strongly advise you
                to review the Privacy Policy of every site you visit.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="linksToOtherSites.text.1" t={t}>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="childrensPrivacy.title" t={t}>
                Children&apos;s Privacy
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="childrensPrivacy.text.0" t={t}>
                Our Service does not address anyone under the age of 18
                (&quot;Children&quot;).
              </Trans>
            </p>
            <p>
              <Trans i18nKey="childrensPrivacy.text.1" t={t}>
                We do not knowingly collect personally identifiable information
                from anyone under the age of 18. If you are a parent or guardian
                and you are aware that your Child has provided us with Personal
                Data, please contact us. If we become aware that we have
                collected Personal Data from children without verification of
                parental consent, we take steps to remove that information from
                our servers.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="changes.title" t={t}>
                Changes to This Privacy Policy
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="changes.text.0" t={t}>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="changes.text.1" t={t}>
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update the
                &quot;effective date&quot; at the top of this Privacy Policy.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="changes.text.2" t={t}>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="contactUs.title" t={t}>
                Contact Us
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="contactUs.text.0" t={t}>
                If you have any questions about this Terms and Conditions,
                please contact us:
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="contactUs.text.1" t={t}>
                  By email: <a href="mailto:info@flagis.com">info@flagis.com</a>
                </Trans>
              </li>
              <li>
                <Trans i18nKey="contactUs.text.2" t={t}>
                  By visiting this page on our website:{' '}
                  <Link language={language} to="/contact-us">
                    https://www.flagis.com/contact-us
                  </Link>
                </Trans>
              </li>
            </ul>
          </ContentSubPage>
        </ContentMain>
        <Footer />
      </div>
      <CookiesBox />
    </>
  )
}

export default PrivacyPolicy

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
